// Observe if searchform goes under header
(function(document, window) {

    const   body = document.querySelector('body'),
            formWrapper = document.querySelector('.searchform-wrapper'),
            header = document.querySelector('#header-navigation')
    ;

    function handleScroll() {

        if(!formWrapper) return;

        if(formWrapper.getBoundingClientRect().bottom <= header.getBoundingClientRect().bottom) {
            body.classList.add('fusion-form');
        } else {
            body.classList.remove('fusion-form');
        }
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);
})(document, window);